import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import MessageForm from './components/MessageForm';
import './css/light.css';
import './css/custom.css';
import './css/main.css';
import { initializeSidebarToggle } from './js/app.js';
import { messageInit } from './js/messages.js';

function App() {
    useEffect(() => {
        initializeSidebarToggle();  // Call the function when the component mounts
        messageInit();
    }, []);
    return (
        <Router>
            <div className="wrapper">
                <Sidebar/>
                <div className="main">
                    <Navbar/>
                    <Routes>
                        <Route path="/" element={<MainContent/>}/>
                        <Route path="/messages/create/" element={<MessageForm />} />
                    </Routes>
                    <Footer/>
                </div>
            </div>
        </Router>
    );
}

export default App;