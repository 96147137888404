import React from 'react';
import PropTypes from 'prop-types';

const PortalCard = ({
                  title,
                  icon,
                  children,
                  className = '',
                  headerClassName = '',
                  bodyClassName = '',
                  marginTop = 5
              }) => {
    return (
        <div className={`card mt-${marginTop} ${className}`}>
            <div className={`card-header ${headerClassName}`}>
                <div className="card-actions float-end">
                    <i className={`fas fa-fw ${icon} fa-lg`}></i>
                </div>
                <h5 className="card-title mb-0">{title}</h5>
                <hr className="mb-0" />
            </div>
            <div className={`card-body ${bodyClassName}`}>
                {children}
            </div>
        </div>
    );
};

PortalCard.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    marginTop: PropTypes.number
};

export default PortalCard;