import React from 'react';
import { Link } from 'react-router-dom';

function MainContent() {
    return (
        <main className="content">
            <div className="container-fluid p-0">
                <h5>Developer Links</h5>
                <Link to="/messages/forms/add" className="btn btn-primary">Add Message</Link>
            </div>
        </main>
    );
}

export default MainContent;