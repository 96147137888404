// components/Navbar.js
import React from 'react';

function Navbar() {
    return (
        <nav className="navbar navbar-expand navbar-light navbar-bg">
            <a className="sidebar-toggle">
                <i className="hamburger align-self-center"></i>
            </a>

            <form className="d-sm-inline-block" method="post" action="https://torodev.visionamp.com/">
                <div className="input-group input-group-navbar">
                    <input type="text" className="form-control" id="main_search_term" name="q" placeholder="Search Dealer Zone" aria-label="Search" />
                    <button className="btn" type="submit" title="Submit">
                        <i className="align-middle" data-feather="search"></i>
                    </button>
                </div>
            </form>

            <div className="d-flex justify-content-between align-items-center">
                <div className="d-inline-flex cpl-20 align-items-center" style={{whiteSpace: "nowrap"}}>
                    <a href="https://torodev.visionamp.com/"><i className="align-middle me-1 fas fa-fw fa-arrows-alt text-red"></i><small><span><strong>Edit Page Layout</strong></span></small></a>
                </div>
            </div>

            <div className="navbar-collapse collapse">
                <ul className="navbar-nav navbar-align">
                    <li className="nav-item dropdown">
                        <a className="nav-icon dropdown-toggle" href="#" data-bs-toggle="dropdown" title="Messages">
                            <div className="position-relative">
                                <i className="align-middle" data-feather="message-circle"></i>
                                <span className="indicator" id="unread_message_badge">4</span>
                            </div>
                        </a>

                        <div className="dropdown-menu dropdown-menu-end" id="message-dropdown">
                        </div>
                    </li>

                    <li className="nav-item dropdown">
                        <a className="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#" data-bs-toggle="dropdown" title="Notifications">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-lucide="settings" className="lucide lucide-settings align-middle"><path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                        </a>

                        <a className="nav-link dropdown-toggle d-none d-sm-inline-block" href="#" data-bs-toggle="dropdown" title="Notifications">
                            <img src="https://placehold.co/500x500" className="img-fluid rounded-circle me-1 mt-n2 mb-n2" alt="Chris Wood" width="40" height="40" /> <span>User Name</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                            <a className="dropdown-item" href="https://torodev.visionamp.com/"><i className="align-middle me-1 fas fa-fw fa-user-alt"></i> Example Item</a><br />
                            <a className="dropdown-item" href="https://torodev.visionamp.com/"><i className="align-middle me-1 fas fa-fw fa-question-circle"></i> Example Item</a>
                            <a className="dropdown-item" href="https://torodev.visionamp.com/"><i className="align-middle me-1 fas fa-fw fa-file"></i> Example Item</a>
                            <a className="dropdown-item" href="https://torodev.visionamp.com/"><i className="align-middle me-1 fas fa-fw fa-headphones-alt"></i> Support</a>
                            <a className="dropdown-item" href="https://torodev.visionamp.com/"><i className="align-middle me-1 fas fa-fw fa-pen-alt"></i> Feature Request</a>
                            <a className="dropdown-item" href="https://torodev.visionamp.com/"><i className="align-middle me-1 fas fa-fw fa-phone"></i> Contact Info</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="https://torodev.visionamp.com/"><i className="align-middle me-1 fas fa-fw fa-sign-out-alt"></i> Logout</a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;