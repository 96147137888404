import $ from 'jquery';

export function initializeSidebarToggle() {

    const $sidebar = $('#sidebar');
    const $sidebarToggle = $('.sidebar-toggle');

    $sidebarToggle.off('click');

    $sidebarToggle.on('click', function() {
        $sidebar.toggleClass('collapsed');
    });
}