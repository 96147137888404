import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import sidebarData from './sidebarData.json';

function Sidebar() {
    const [openItem, setOpenItem] = useState(null);
    const location = useLocation();

    useEffect(() => {
        // Find the item that matches the current path and open its parent
        const currentItem = findItemByPath(sidebarData.items, location.pathname);
        if (currentItem && currentItem.parent) {
            setOpenItem(currentItem.parent);
        }
    }, [location]);

    const findItemByPath = (items, path, parent = null) => {
        for (let item of items) {
            if (item.link === path) {
                return { ...item, parent };
            }
            if (item.children) {
                const found = findItemByPath(item.children, path, item.title);
                if (found) return found;
            }
        }
        return null;
    };

    const toggleItem = (itemTitle) => {
        setOpenItem(prevOpenItem => prevOpenItem === itemTitle ? null : itemTitle);
    };

    const renderSidebarHeader = (item) => (
        <li key={item.title} className="sidebar-header">
            {item.title}
        </li>
    );

    const renderSidebarItem = (item) => {
        const isOpen = openItem === item.title;
        const hasChildren = item.children && item.children.length > 0;
        const isActive = item.link === location.pathname ||
            (hasChildren && item.children.some(child => child.link === location.pathname));

        return (
            <li key={item.title} className={`sidebar-item ${isActive ? 'active' : ''}`}>
                <a
                    href={item.link || "#"}
                    data-bs-target={hasChildren ? `#${item.title.replace(/\s+/g, '')}` : null}
                    data-bs-toggle={hasChildren ? "collapse" : null}
                    className={`sidebar-link ${hasChildren ? (isOpen ? '' : 'collapsed') : ''}`}
                    aria-expanded={hasChildren ? (isOpen ? "true" : "false") : null}
                    onClick={(e) => {
                        if (hasChildren) {
                            e.preventDefault();
                            toggleItem(item.title);
                        }
                    }}
                >
                    {item.icon && <i className={`align-middle ${item.icon}`}></i>}
                    <span className="align-middle">{item.title}</span>
                </a>
                {hasChildren && (
                    <ul
                        id={item.title.replace(/\s+/g, '')}
                        className={`sidebar-dropdown list-unstyled collapse ${isOpen ? 'show' : ''}`}
                        data-bs-parent="#sidebar"
                    >
                        {item.children.map((child) => (
                            <li key={child.title} className={`sidebar-item ${child.link === location.pathname ? 'active' : ''}`}>
                                <Link className="sidebar-link" to={child.link || "#"}>
                                    {child.title}
                                    {child.badge && (
                                        <span className={`badge badge-sidebar-${child.badge.type}`}>
                                            {child.badge.text}
                                        </span>
                                    )}
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
            </li>
        );
    };

    const renderSidebarContent = (item) => {
        switch (item.type) {
            case 'divider-pt':
                return <li className="sidebar-item sidebar-divider-padded-top align-middle"></li>;
            case 'divider-pb':
                return <li className="sidebar-item sidebar-divider-padded-bottom align-middle"></li>;
            case 'header':
                return renderSidebarHeader(item);
            case 'item':
            default:
                return renderSidebarItem(item);
        }
    };

    return (
        <nav id="sidebar" className="sidebar">
            <div className="sidebar-content js-simplebar">
                <Link className="sidebar-brand" to="/">
                    <img src={sidebarData.logo.src} alt={sidebarData.logo.alt} className="img-responsive" style={{maxWidth: '100%'}} />
                </Link>
                <ul className="sidebar-nav">
                    {sidebarData.items.map((item) => renderSidebarContent(item))}
                </ul>
            </div>
        </nav>
    );
}

export default Sidebar;