import React from 'react';
import TagList from "./TagList";
import PortalCard from "./PortalCard";

function MessageForm() {
    return (
        <main className="content">
            <div className="container-fluid p-0">


                <form id="create-message-form" method="post" encType="multipart/form-data" onSubmit="return messages.postAPI(event)">
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <h3>New Message/Alert</h3>
                            </div>
                            <div className="d-flex justify-content-between">
                                <input type="submit" value="Save Draft" name="draft_message" className="btn btn-blue me-2"/>
                                <input type="submit" value="Submit" name="submit_message" className="btn btn-red me-2"/>
                            </div>
                        </div>
                        <hr></hr>
                            <div className="row mt-2">
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-actions float-end">
                                                <i className="fas fa-fw fa-paper-plane fa-lg"></i>
                                            </div>
                                            <h5 className="card-title mb-0">Send Settings</h5>
                                            <hr className="mb-0"></hr>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-6 mb-3">
                                                    <label htmlFor="title" className="form-label b-text"><span className="text-red">*</span> Title of Message</label>
                                                    <input type="text" className="form-control input-main" name="message_title" id="title" placeholder="Title of Message" required=""/>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="list" className="form-label b-text">
                                                        <span className="text-red">*</span> List(s) to Send To:
                                                    </label>
                                                    <div className="dropdown">
                                                        <button className="btn dropdown-toggle input-main w-100 text-start d-flex justify-content-between align-items-center" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                                                            <span>Select All Applicable Lists</span>
                                                            <span className="dropdown-toggle-icon"></span>
                                                        </button>
                                                        <ul className="dropdown-menu custom-dropdown-menu w-100" aria-labelledby="dropdownMenuButton" id="sendToList">
                                                            <li>
                                                                <label className="dropdown-item">
                                                                    <input type="checkbox" className="form-check-input me-2" name="send_to[]" value="list1" data-id="1"/> List 1
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="dropdown-item">
                                                                    <input type="checkbox" className="form-check-input me-2" name="send_to[]" value="list2" data-id="2"/> List 2
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="dropdown-item">
                                                                    <input type="checkbox" className="form-check-input me-2" name="send_to[]" value="list3" data-id="3"/> List 3
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="dropdown-item">
                                                                    <input type="checkbox" className="form-check-input me-2" name="send_to[]" value="list4" data-id="4" /> List 4
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3 mb-3">
                                                    <label htmlFor="publishDate" className="form-label b-text">Publish On:</label>
                                                    <input type="date" value="2024-08-28" className="form-control input-main" name="publish_date" id="publishDate" required=""/>
                                                </div>
                                                <div className="col-sm-3 mb-3">
                                                    <label htmlFor="archiveDate" className="form-label b-text">Archive On:</label>
                                                    <input type="date" value="2024-09-28" className="form-control input-main" name="archive_date" id="archiveDate" required=""/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <label className="form-label b-text">Lock this Message?</label>
                                                    <div className="d-inline-flex">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" id="lockYes" name="lock_message" value="1"/>
                                                                <label className="form-check-label" htmlFor="lockYes">
                                                                    Yes
                                                                </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" id="lockNo" name="lock_message" value="0" defaultChecked />
                                                                <label className="form-check-label" htmlFor="lockNo">
                                                                    No
                                                                </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3">
                                                    <label className="form-label b-text">Require 'Mark as Read'?</label>
                                                    <div className="d-inline-flex">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" id="markAsReadYes" name="mark_as_read" value="1"/>
                                                                <label className="form-check-label" htmlFor="markAsReadYes">
                                                                    Yes
                                                                </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" id="markAsReadNo" name="mark_as_read" value="0" defaultChecked />
                                                                <label className="form-check-label" htmlFor="markAsReadNo">
                                                                    No
                                                                </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3">
                                                    <label className="form-label b-text">Notify via:</label><br></br>
                                                    <div className="d-inline-flex">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" id="notifyNone" name="notify_via" value="none" defaultChecked />
                                                                <label className="form-check-label" htmlFor="notifyNone">
                                                                    None
                                                                </label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" id="notifyEmail" name="notify_via" value="email"/>
                                                                <label className="form-check-label" htmlFor="notifyEmail">
                                                                    Email
                                                                </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" id="notifyText" name="notify_via" value="text"/>
                                                                <label className="form-check-label" htmlFor="notifyText">
                                                                    Text
                                                                </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <PortalCard icon={"fa-pen"} title={"Message Content"}>
                                        <div className="d-flex justify-content-center mt-5 mb-3">
                                            <div className="mt-1 me-4">
                                                <h4>Add a Section</h4>
                                            </div>
                                        </div>
                                        <div id="sections-container">

                                        </div>
                                    </PortalCard>

                                </div>
                                <div className="col-md-4">
                                    <PortalCard
                                        title="Sorting Tags"
                                        icon="fa-tags"
                                        marginTop={0}
                                    >
                                        <TagList />
                                    </PortalCard>


                                    <PortalCard
                                        title="Attachments"
                                        icon="fa-paperclip"
                                        marginTop={5}
                                    >
                                        <div className="file-uploader-box" id="fileUploaderBox">
                                            <div>
                                                <i className="fas fa-fw fa-cloud-upload-alt fa-3x"></i>
                                            </div>
                                            <div>Click Here or Drag & Drop to Add a New Document</div>
                                        </div>
                                        <input type="file" id="fileInput" name="files[]" className="d-none" />
                                        <div className="attachments-container mt-4" id="attachmentsContainer" style={{display: 'none'}}></div>
                                        <div className="files-container mt-4" id="filesContainer" style={{display: 'none'}}></div>
                                    </PortalCard>
                                </div>
                            </div>
                            <hr></hr>
                            <input type="hidden" name="author" value="Toro"/>
                            <div className="row mt-3">
                                <div className="d-flex justify-content-between">
                                    <input type="submit" value="Save Draft" name="draft_message" className="btn btn-blue me-2"/>
                                        <div className="d-inline-flex">
                                            <input name="api_url" value="https://toroportal.volanosoftware.com/api/v1/Messages" className="form-control input-alt me-4" type="text" placeholder="Enter API URL" style={{ width: '500px;' }} required=""/>
                                            <input type="submit" value="Submit" name="submit_message" id="message_submit_form" className="btn btn-red me-2"/>
                                        </div>
                                </div>
                            </div>
                            <div className="display_api_results d-none mt-5">
                                <hr></hr>
                                <h3>Results Below:</h3>
                            </div>
                    </div>
                </form>
            </div>
        </main>
    );
}

export default MessageForm;